import React from "react";
import HelmetWrapper from "../components/helmet-wrapper";
import Layout from "../components/layout";
import CountupClock from "../components/since/countup-clock";

const YearTen = ({ location }) => {
  return (
    <Layout location={location} killPadding={true} killFooter={true}>
      <HelmetWrapper pageTitle="Year Ten" />
      <div className="bg-theme2-bg-1 flex flex-col min-h-1/3 justify-end p-4 pb-20">
        <div className="font-serif-2 text-xl text-theme2-primary md:w-[640px] md:mx-auto md:text-2xl md:mt-20">
          <div className="mb-3">
            On December 12 of this year, we will have been married for one
            decade.
          </div>
          <div className="mb-3">
            We want to celebrate that, with as many of our loved ones as we can.
            We have a new story to tell, new friends to share it with, and this
            time we’ll have dancing.
          </div>
          <div className="mb-3">We’d really love for you to join us.</div>
          <div className="mb-3">
            The major event is going to be a multi-media storytelling about
            these ten years and all that has happened and changed and stayed the
            same. It will be a thoughtful and precious and modest show, followed
            by the opposite kind of dancing. The evening before we’ll be
            performing a reprise of our original wedding show, and the days
            after we plan to simply hang out with you all as much as possible.
          </div>
          <div className="mb-3">
            Both shows will be in the evening, starting at 7p, so if you’re
            local and need to work during the day—or visiting from out of town,
            working remotely, and need to work during the day—you may! Friday
            and Saturday we’ll be free and hanging out doing various activities
            which you’re welcome to join in if you can, but other than Wednesday
            and Thursday evening there are no narratively significant events.
          </div>
          <div className="mb-3">
            As a mildly embarrassing note, we have spent all of our riches on{" "}
            <a
              href="https://thecompany.social/"
              target="_blank"
              rel="noreferrer"
              className="text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2"
            >
              renting out the venue for a whole entire year
            </a>{" "}
            and therefore do not plan to properly feed you, with two exceptions:
            Sabbath Dinner on Friday evening (if you’re interested), and
            Saturday morning pancakes and egg sandwiches (if you’re interested).
            Otherwise, we are scheduling the shows and other activities such
            that you have opportunity to enjoy any of the great food that Denver
            has to offer.
          </div>
          <div className="mb-3">
            Dress in whatever you love dressing in. If that’s unhelpful (perhaps
            you look stunning in everything), note that it’ll be warm in the
            venue but about 30º outside.
          </div>
          <div className="mb-3">
            To be clear! If you’re trying to decide about what things to catch,
            the very most important thing to attend is the show on December 12,
            followed by the show on December 11. If you only make it to those
            two things, you’ll have experienced almost the entirety of what our
            energy has been going into.
          </div>
          <div className="mb-3">
            RSVP by December 4 (links at the bottom of this page).
          </div>
        </div>
        <div className="my-20">
          <div className="w-fit mx-auto min-h-[252px] md:min-h-[528px] lg:min-h-[352px] grid grid-rows-3 lg:grid-rows-2 grid-cols-2 lg:grid-cols-3">
            <CountupClock />
          </div>
        </div>
        <div className="md:w-[640px] md:mx-auto mb-4">
          <div className="font-sans font-semibold text-4xl text-theme2-primary mb-2">
            Ten Years Later: A&nbsp;Celebration
          </div>
          <div className="font-serif-2 text-theme2-primary text-lg">
            December 11–14 | Denver, Colorado
          </div>
        </div>
        <div className="md:w-[640px] md:mx-auto mb-4">
          <div className="font-sans uppercase text-theme2-primary text-xs font-semibold tracking-widest py-4">
            Tentative Schedule
          </div>
          <ScheduleDay
            date="Wed Dec 11"
            items={[
              {
                type: "Pre Show",
                title: "The Marriage of Ivan and Livvy (A Reprise)",
                locationTime:
                  "<a href='https://maps.app.goo.gl/16xK5eiynFWLMHXi9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>The Company</a> • Doors at 7p, show from 7:30p–8:30p",
                description:
                  "We got married in a performance hall instead of a church, and instead of doing normal wedding things we both got on stage and told the story of how we met and got together. It’s one of our favorite things we’ve ever done, and if you didn’t catch it the first time around, we think it’s pretty great and it gives some useful context to who we became. Seeing it is not a prerequisite for enjoying the main show, but we’d love to share it with you.",
              },
            ]}
          />
          <ScheduleDay
            date="Thu Dec 12"
            items={[
              {
                type: "Main Show",
                title: "Ten Years Later",
                locationTime:
                  "<a href='https://maps.app.goo.gl/16xK5eiynFWLMHXi9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>The Company</a> • Doors at 7p, show from 7:30p–8:30p",
                description:
                  "A lot has happened in ten years. We still recognize ourselves, but a lot has changed;  we want to reflect on that time, update some vows, and celebrate everything and everyone that has made these years what they’ve been. And so again with the multi-media storytelling live-show format. Hear about the ups and ups, the dizzying loss, and where our love has taken us.",
              },
              {
                type: "Party",
                title: "Dance till Eleven",
                locationTime:
                  "<a href='https://maps.app.goo.gl/16xK5eiynFWLMHXi9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>The Company</a> • 9p–11p",
                description:
                  "Following the show, we’ll have a dance party (that’s a last-ten-years thing—we dance now and enjoy it). If you do not currently consider yourself a dancer, please know that it is fully acceptable to just wiggle around awkwardly in time to the music. That, in fact, is dancing. If you’re moving your body and having fun that’s dancing. Learning this is what made us enjoy dancing.",
              },
            ]}
          />
          <ScheduleDay
            date="Fri Dec 13"
            items={[
              {
                type: "Meal",
                title: "Breakfast at Leven",
                locationTime:
                  "<a href='https://maps.app.goo.gl/1zgs52aG7e4Vs3qW9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>Leven Deli Co.</a> • 8a–10a",
                description:
                  "This <a href='https://eatleven.com/' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'> delicious deli</a> is a 5-minute walk from the museum. Show up at whatever time you want and get yourself a table (or crowd in with friends already there). You’ll pay for yourself ($10–$30).",
              },
              {
                type: "Museum",
                title: "Wild Things Exhibit",
                locationTime:
                  "<a href='https://maps.app.goo.gl/WqGghWk3sdQjHtRd9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>Denver Art Museum</a> • 10:15a–12:30p",
                description:
                  "Get tickets through us for a special discounted group rate of $19. If you are a DAM member, get $5 tickets for the 10:40a slot <a href='https://tickets.denverartmuseum.org/DateSelection.aspx?item=2983' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>directly at this link</a>.",
              },
              {
                type: "Meal",
                title: "Lunch at Linger",
                locationTime:
                  "<a href='https://maps.app.goo.gl/Sx8PB8U2MpAwe2ZTA' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>Linger Eatuaries</a> • 1p–2p",
                description:
                  "Linger’s a little pricey ($30–$50) but very delicious, and just a block away from The Company. Importantly, it shares a name with our favorite activity. If you’d like a more affordable but very Denver option, we recommend <a href='https://maps.app.goo.gl/Gm5K5zcvuYFJf9Eg6' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>Torchy’s Tacos</a> ($10–$20), which is just a block away from the museum. You could even do both! Fill up on Torchy’s and just grab apps and drinks at Linger.",
              },
              {
                type: "Games",
                title: "Games at The Company",
                locationTime:
                  "<a href='https://maps.app.goo.gl/16xK5eiynFWLMHXi9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>The Company</a> • Doors at 2:30p, games from 3p–5:30p",
                description:
                  "We’ll be playing The Story Game and Parliament (also known as Couch of Power). Please be on time!",
              },
              {
                type: "Meal",
                title: "Sabbath Dinner",
                locationTime:
                  "<a href='https://maps.app.goo.gl/16xK5eiynFWLMHXi9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>The Company</a> • Doors at 6p, meal at 6:30p",
                description:
                  "One of the most important and regulating rituals in our lives has been a weekly Friday-evening dinner party, loosely informed by the faith we were raised in. We started it with our friend Daisy in Boston, restarted here in Denver, and two months before this celebrated our 100th Denver Sabbath Dinner. Depending on how many people RSVP to this, we’ll split into groups and, over soup and bread, go through the three questions we talk through every week: what was hard about your last seven days?, what was good about your last seven days?, and what’s coming up in your next seven days?",
              },
            ]}
          />
          <ScheduleDay
            date="Sat Dec 14"
            items={[
              {
                type: "Meal",
                title: "Brunch",
                locationTime:
                  "<a href='https://maps.app.goo.gl/XcWGFizxitnf97Rt5' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>Our place</a> • Doors at 9a, food sometime after that",
                description:
                  "Right after we got married, still living in Berrien Springs, Livvy still in school, we hosted Sabbath Breakfasts on Saturday mornings. I’d make pancakes and Livvy would make egg sandwiches. Having worked at the town-famous Baguette de France, she had a few opinions about sandwich ordering processes, and made a sandwich-ordering Google form. If you’d like to come and eat pancakes and a sandwich, we’ll be dusting off those forms.",
              },
              {
                type: "Crafts",
                title: "Crafts and Conversation",
                locationTime:
                  "<a href='https://maps.app.goo.gl/16xK5eiynFWLMHXi9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>The Company</a> • 1p",
                description:
                  "Most of our hobbies involve making things. We think it’s a fun and rewarding way to spend time. As a bit of a break to the celebratory activities, we’ll take some time to just do crafty things at The Company, while we probably also talk. Bring your knitting or your drawing materials or your coloring books. We’ll also have some things to occupy you.",
              },
              {
                type: "Meal",
                title: "Dinner at Avanti",
                locationTime:
                  "<a href='https://maps.app.goo.gl/XTyRwkrzj4ULBMyV6' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>Avanti</a> • 4p",
                description:
                  "Avanti is one of those food hall kind of places ($10–$20). We almost only get the arepas from Quiero Arepas. While a little bit further than a block away from The Company, it’s still walkable.",
              },
              {
                type: "Show",
                title: "PowerPoint Party No. 21",
                locationTime:
                  "<a href='https://maps.app.goo.gl/16xK5eiynFWLMHXi9' target='_blank' class='text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2'>The Company</a> • Doors at 6:30p, show at 7p",
                description:
                  "In addition to Sabbath Dinners, a very significant part of our lives these days have been PowerPoint Parties. Our friend Kelsey invited us to our first one, one Christmas when we were back home. We loved the idea and copied it and have been doing them roughly monthly for a while now, and they’ve led to so many good things for us. We’ve made and deepened some of favorite friendships at these.",
              },
            ]}
          />
        </div>
        <div className="font-serif-2 text-lg text-theme2-primary md:w-[640px] md:mx-auto mt-20">
          <div className="font-serif-2 text-theme2-primary text-lg">
            <div>Consider this link the full invitation!</div>
            <div className="mt-4">
              Please RSVP (or indicate interests) by December 4<br />
              using these forms:
            </div>
            <div className="mt-4">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdc_Clj6vvPmvD_Jr4StaPZ0lVx_Bv8-T90ukHo2ibcSiwGmQ/viewform"
                target="_blank"
                rel="noreferrer"
                className="block text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2"
              >
                Ruiz-Knott Year 10 Party: Main Events
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSe-VipexU5JGJNAzZSO1R8ASEJBinzeH3qQ0TMH-mTBfVJDAA/viewform"
                target="_blank"
                rel="noreferrer"
                className="block text-theme2-accent underline decoration-1 decoration-theme2-accent underline-offset-2"
              >
                Ruiz-Knott Year 10 Party: Activities
              </a>
            </div>
          </div>

          <div className="md:w-96"></div>
        </div>
      </div>
    </Layout>
  );
};

export default YearTen;

const ScheduleDay = ({ date, items }) => {
  return (
    <div className="py-4 border-t last:border-b border-theme2-bg-3 flex flex-col md:flex-row gap-4">
      <div className="w-32 text-theme2-secondary text-xs uppercase font-semibold tracking-widest">
        {date}
      </div>
      <div className="flex flex-col gap-4">
        {items.map((item, i) => {
          return (
            <div key={`scheduled-item-${i}`} className="">
              <div className="text-theme2-secondary text-xs uppercase font-semibold tracking-widest mb-2">
                {item.type}
              </div>
              <div className="font-serif-2 text-2xl text-theme2-primary leading-7 mb-1">
                {item.title}
              </div>
              <div
                className="text-theme2-accent text-xs uppercase font-semibold tracking-widest mb-3"
                dangerouslySetInnerHTML={{ __html: item.locationTime }}
              ></div>
              <div
                className="font-extralight font-sans text-lg text-theme2-primary md:max-w-2xl md:mx-auto mb-2 opacity-80"
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
